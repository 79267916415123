import React from 'react'
import { graphql, navigate } from 'gatsby';
import dayjs from 'dayjs'

import { theme } from '@/styles/theme'

import sanitizeHtml from '@/lib/sanitize-html/sanitizeHtml'
import { Layout } from '@/components/layouts/Layout'
import styled from 'styled-components'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { Seo } from '@/components/Seo'
import { MainWrapper } from '@/styles/common'
import { Button } from '@/components/elements/Button'

type MicrocmsNotice = {
  noticeId: string;
  notice: string;
  display: string;
  content: string;
  start: string;
  end: string;
};
type AllNoticesQuery = {
  allMicrocmsNotice: {
    nodes: MicrocmsNotice[];
  };
};

type NoticeProps = {
  data: {
    allMicrocmsNotice: {
      nodes: MicrocmsNotice[];
    };
  };
  pageContext: {
    noticeId: string;
  };
};
export const query = graphql`
  query {
    allMicrocmsNotice (sort: { fields: start, order: DESC }){
      nodes {
        noticeId
        notice
        display
        content
        start
        end
      }
    }
  }`;

const Notice: React.FC<NoticeProps> = ({ data, pageContext }) => {
  const { nodes: allNotices } = data.allMicrocmsNotice;
  const currentIndex = allNotices.findIndex(notice => notice.noticeId === pageContext.noticeId);

  if (currentIndex === -1) {
    return <div>Notice not found</div>;
  }

  const currentNotice = allNotices[currentIndex];
  const previousNotice = currentIndex > 0 ? allNotices[currentIndex - 1] : null;
  const nextNotice = currentIndex < allNotices.length - 1 ? allNotices[currentIndex + 1] : null;

  const breadCrumbs = [
    {
      name: 'トップ',
      path: '/',
    },
    {
      name: 'お知らせ',
      path: '/notices/',
    },
    {
      name: currentNotice.notice,
    }
  ];

  return (
    <Layout appHide>
      <Seo
        title="お知らせ"
        canonical="https://su-pay.jp/notices/"
        description="SU-PAYについてのお知らせはこちら。"
      />
      <Title as="h1" bg={true}>
        お知らせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <p>
            {dayjs(currentNotice.start).format('YYYY/MM/DD(dd)')}
          </p>
          <Title as="h2" color={theme.colors.supayBlue}>
            {currentNotice.notice}
          </Title>
        </TitleWrapper>
        <div
          dangerouslySetInnerHTML={{
            __html: `${sanitizeHtml(currentNotice.content)}`,
          }}
        />
        <PageButton>
          {previousNotice && (
            <Button onClick={() => navigate(`/notices/${previousNotice.noticeId}`)}>前へ</Button>
          )}
          <Button onClick={() => navigate(`/notices`)}>一覧</Button>
          {nextNotice && (
            <Button onClick={() => navigate(`/notices/${nextNotice.noticeId}`)}>次へ</Button>
          )}
        </PageButton>
      </MainWrapper>
    </Layout>
  );
};

export default Notice;

const TitleWrapper = styled.div`
  padding: 46px 0px 22px;

  h2 {
    line-height: 52px;
    text-align: left;
  }

  p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1047px) {
    margin: 60px 0 40px;

    h2 {
      line-height: 24px;
      font-size: 20px;
    }

    p:nth-child(1) {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
`

const PageButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  button {
    margin-right: 10px;
    width: 120px;
  }
  @media screen and (max-width: 1047px) {
    button {
      width: 94px;
    }
  }
  @media screen and (max-width: 450px) {
    button {
      width: 95px;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 370px) {
    button {
      width: 80px;
      height: 50px;
      font-size: 12px;
    }
  }
`
